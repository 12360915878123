@charset "utf-8";

.banners {
  img {
    float: left; } }

.header {
  font-weight: 900; }

.header.border {
  a {
    border: outset 2px; } }

.title, .small-title {
  h2 {
    background: rgba(0,0,0,0.25);
    padding-left: 15px;
    padding-right: 15px;
    display: inline; } }

.title {
  h2 {
    padding-top: 10px;
    padding-bottom: 10px; } }

.button {
  color: #000; }

.contact-details .icon {
  text-align: center; }

.contact-details i {
  font-size: 3.75em; }

